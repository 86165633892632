import axios from "axios"

const getFindingsDetails = (idExam) => {
  const url = `http://www.api-plataforma.machiron.com.br/exam/${idExam}/get_alt_json`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

const deleteFinding = (idFiding) => {
  const url = `https://www.api-plataforma.machiron.com.br/alt/${idFiding}/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.delete(url, config)
}

export { getFindingsDetails, deleteFinding }