<script setup>
import { defineProps } from 'vue'
import useImageStore from '@/stores/images'
import useFindingsDetailsStore from '@/stores/findings'
import useGeneralStore from '@/stores/general'

const findingsDetails = useFindingsDetailsStore()
const storeImage = useImageStore()
const storeGeneral = useGeneralStore()

const props = defineProps({
  isVector: Boolean,
  isImage: Boolean,
  isDownload: Boolean
})

const getImagePath = ((num) => {
  return require(`@/assets/teeths/${num}.png`)
})

const showImageFull = () => {
  window.open(storeImage.image, 'Galeria', 'width=1100,height=600')
}

</script>

<template>
  <div 
    v-if="storeGeneral.isRequestLoad"
    class="cmp-odontogram-vision"
    :class="{ 'download': props.isDownload }"
  >
    <!-- vector show intro -->
    <div 
      v-if="props.isVector"
      class="cmp-odontogram-vision__vector"
    >
      <div class="cmp-odontogram-vision__bg"></div>
      <div class="cmp-odontogram-vision__teeths">
        <div class="cmp-odontogram-vision__teeths--upper">
          <div
            v-for="i in [
              18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
            ]"
            :key="i"
            class="cmp-odontogram-vision__teeths--wrap"
            :class="{ 
                'teeth_alt': findingsDetails?.getTeethAlt?.value.includes(i),
                'ausente': findingsDetails?.getTeethOut?.includes(i)
              }"
          >
            <img
              :src="getImagePath(i)"
              :alt="`Dente ${i}`"
              class="cmp-odontogram-vision__teeths--img"
            />
            <span class="cmp-odontogram-vision__teeths---index">{{ i }}</span>
          </div>
        </div>
        <div class="cmp-odontogram-vision__teeths--down">
          <div
            v-for="i in [
              48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
            ]"
            :key="i"
            class="cmp-odontogram-vision__teeths--wrap"
            :class="{ 
                'teeth_alt': findingsDetails?.getTeethAlt?.value.includes(i),
                'ausente': findingsDetails?.getTeethOut?.includes(i)
              }"
          >
            {{ i }}
            <img
              :src="getImagePath(i)"
              :alt="`Dente `"
              class="cmp-odontogram-vision__teeths--img"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- vector show end -->

    <!-- image show intro -->
    <div 
      v-if="props.isImage"
      class="cmp-odontogram-vision__image"
    >
      <img
        :src="storeImage.image"
        alt="Panorâmica"
        class="cmp-odontogram-vision__image--item"
      />
      <button 
          class="cmp-odontogram-vision__image--button d-flex align-center justify-center"
          @click="showImageFull"
        >
          <img
            src="@/assets/icons/gallery.svg"
            alt="Icon"
            class="cmp-odontogram-vision__image--icon"
          />
        </button>
    </div>
    <!-- image show end -->
  </div>
</template>

<style lang="scss" scoped>
  @import '_odontogram-vision.scss'
</style>
