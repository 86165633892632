import { computed, ref } from 'vue'
import * as state from './state'

export const getFindingsDetails = computed(() => {
  return state.findingsDetails.value
})

export const getTeethOut = computed(() => {
  const findings = state.findingsDetails.value
  const teethOut = Object.keys(findings)
    .filter(key => findings[key].fields.id_alteracao === 1)
    .map(key => findings[key].fields.num_dente)
  
  return JSON.parse(JSON.stringify(teethOut));
})

export const getTeethAlt = computed(() => {
  const teethAlt = ref([])
  state.findingsDetails.value.forEach(item => {
    if(item.fields.id_alteracao !== null && item.fields.num_dente !== null) {
      teethAlt.value.push(item.fields.num_dente)
    }
  })
  return teethAlt
})