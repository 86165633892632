<script setup>
import { computed } from 'vue';
import useExamStore from '@/stores/exam'
import useFindingsDetailsStore from '@/stores/findings'

const storeExam = useExamStore()
const storeFindings = useFindingsDetailsStore()

const dentesAusentesAgrupados = computed(() => {
  const findings = storeFindings.getFindingsDetails
  
  const teethOut = Object.keys(findings)
    .filter(key => findings[key].fields.id_alteracao === 1)
    .map(key => findings[key].fields.num_dente)
  
  storeFindings.setTeethOut(teethOut)
  return teethOut
})

</script>


<template>
  <div class="cmp-teeth-type">
    <h3 class="cmp-teeth-type__title">Tipo de Dentição (com ausentes e extranum.)</h3>
    <div class="cmp-teeth-type__items">
      <div class="cmp-teeth-type__teeth-permanent">
        <p>
          {{ storeExam.mapTipoDenticao }}
        </p>
      </div>
      <div class="cmp-teeth-type__teeth-out">
        <p>
          {{ dentesAusentesAgrupados.length }} Elementos ausentes: {{ dentesAusentesAgrupados.join(', ') }}
        </p>
      </div>
      <div class="cmp-teeth-type__num-ext">
        <p>Nenhum elemento extranumerário</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '_teeth-type.scss'
</style>
