<script setup>
import useGeneralStore from '@/stores/general'

const storeGeneral = useGeneralStore()

const achados = [
  'Reabsorção radicular externa',
  'Arredondamento apical'
]

const obs = 'A critério clínico sugerimos radiografia periapical.'

</script>

<template>
  <div class="modal-edit">
		<div class="modal-edit__content">
			<span 
				class="modal-edit__close-button"
				@click="storeGeneral.toggleModalEdit(false)"
			>X</span>
			<h3 class="modal-edit__title">Editar achado</h3>
			<div class="modal-edit__form">
        <div class="form-select">
          <select name="dente" id="dente">
            <option value="">Dente 11</option>
            <option value="">Dente 12</option>
          </select>
        </div>
        
        <input 
          v-for="achado in achados"
          :key="achado"
          type="text"
          :value="achado"
        >

        <h3 class="modal-edit__subtitle">Observação</h3>
        <textarea name="observacao" id="" cols="30" rows="3" :value="obs"></textarea>
			</div>
			<button class="modal-edit__button-confirm">Salvar</button>
			<button class="modal-edit__button-cancel">Cancelar</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import './modal_edit.scss'
</style>