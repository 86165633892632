import * as state from './state'

export const setIsRequestLoad = (value) => {
  state.isRequestLoad.value = value
}

export const toggleModalDelete = (value) => {
  state.isModalDelete.value = value
}

export const toggleModalEdit = (value) => {
  state.isModalEdit.value = value
}