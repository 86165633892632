// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/odontogram-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cmp-odontogram-vision[data-v-bbdcdef0]{width:100%;max-width:60.625rem;height:32.6875rem;display:flex;align-items:center;justify-content:center;position:relative}.cmp-odontogram-vision__vector[data-v-bbdcdef0]{border:2px solid #dfdfdf;border-radius:.5rem;padding:1rem;width:100%;height:32.6875rem}.cmp-odontogram-vision__image[data-v-bbdcdef0]{width:100%;height:32.6875rem;position:relative}.cmp-odontogram-vision__image--item[data-v-bbdcdef0]{width:100%;height:95%;border-radius:.5rem}.cmp-odontogram-vision__image--button[data-v-bbdcdef0]{background-color:#514e99;width:3rem;height:3rem;border-radius:.5rem;position:absolute;bottom:2.5rem;right:1rem}.cmp-odontogram-vision__bg[data-v-bbdcdef0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:contain;width:100%;height:100%}.cmp-odontogram-vision__teeths[data-v-bbdcdef0]{position:absolute;top:55%;left:50%;transform:translate(-50%,-50%)}.cmp-odontogram-vision__teeths img[data-v-bbdcdef0]{max-width:85%;height:auto}.cmp-odontogram-vision__teeths--down[data-v-bbdcdef0],.cmp-odontogram-vision__teeths--upper[data-v-bbdcdef0]{display:flex}.cmp-odontogram-vision__teeths--upper[data-v-bbdcdef0]{align-items:end}.cmp-odontogram-vision__teeths--down[data-v-bbdcdef0]{align-items:start}.cmp-odontogram-vision__teeths--wrap[data-v-bbdcdef0]{display:flex;justify-content:center;align-items:center;flex-direction:column;padding:.15rem;cursor:pointer}.cmp-odontogram-vision__teeths--index[data-v-bbdcdef0]{font-size:.875rem;font-weight:400;line-height:1.25rem;text-align:center}.ausente img[data-v-bbdcdef0]{opacity:.15}.teeth_alt[data-v-bbdcdef0]{position:relative}.teeth_alt[data-v-bbdcdef0]:after{content:\"\";width:90%;height:90%;border:.125rem solid #cd1714;position:absolute}.download[data-v-bbdcdef0]{height:29.5rem}.download .cmp-odontogram-vision__vector[data-v-bbdcdef0]{border:none;height:29.5rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
