<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col v-if="showDrawer" class="d-none d-lg-flex" cols="2">
        <NavigationDrawer />
      </v-col>

      <v-col :cols="showDrawer ? 10 : 12" class="content-wrap">
        <v-row no-gutters>
          <v-col>
            <div class="d-flex align-center justify-center mt-12">
              <span class="font-weight-bold text-h3-base"
                >Exames - Papiron</span
              >
            </div>
            <div class="d-flex align-center justify-center mt-12">
              <v-card width="100%" class="mx-12" variant="outlined">
                <DataTable
                  :headers="tableHeaders"
                  :items="tableData"
                  :sortBy="sortBy"
                  :itemsPerPage="itemsPerPage"
                  @row-click="fetchExamDetails"
                  @exam-deleted-successfully="handleExamDeletedSuccessfully"
                />
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import NavigationDrawer from "@/components/navigationDrawer.vue"
  import DataTable from "@/components/dataTable.vue"
  export default {
    name: "ListPage",
    components: {
      NavigationDrawer,
      DataTable,
    },
    data() {
      return {
        showDrawer: true,
        sortBy: [{ key: "dt_received", order: "desc" }],
        itemsPerPage: 10,
        tableHeaders: [
          {
            title: "Identificador",
            key: "nm_description",
            align: "start",
            width: "30%",
          },
          {
            title: "Descrição",
            key: "nm_source",
            align: "start",
            width: "20%",
          },
          { title: "Status", key: "sg_status", align: "center", width: "20%" },
          {
            title: "Recebido em",
            key: "dt_received",
            align: "start",
            width: "10%",
            sortable: true,
          },
          {
            title: "Modificado em",
            key: "dt_modified",
            align: "start",
            width: "10%",
          },
          {
            title: "Ações",
            align: "center",
            key: "actions",
            width: "10%",
            sortable: false,
          },
        ],
        tableData: [],
      }
    },
    methods: {
      async fetchTableData() {
        try {
          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            "http://www.api-plataforma.machiron.com.br/exam/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao obter dados")
          }

          this.tableData = await response.json()
          this.tableData.forEach((item) => {
            switch (item.sg_status) {
              case "E":
                item.sg_status = "Incompleto"
                break
              case "EC":
                item.sg_status = "Erro"
                break
              case "U":
                item.sg_status = "Em processamento"
                break
              case "P":
                item.sg_status = "Processado"
                break
              case "R":
                item.sg_status = "Revisado"
                break
              default:
                break
            }
          })
        } catch (error) {
          console.error("Erro ao obter dados:", error)
        }
      },
      handleRowClick(item) {
        console.log("Item clicado:", item)

        this.fetchExamDetails(item)
      },

      async fetchExamDetails(item) {
        try {
          if (!item.item.id_exam) {
            console.error("Id_exam não está definido no item:", item)
            return
          }

          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `http://www.api-plataforma.machiron.com.br/pat/${item.item.id_exam}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao obter detalhes do exame")
          }
          this.$router.push({
            name: "ExameDetails",
            params: { id_exam: item.item.id_exam },
          })
        } catch (error) {
          console.error("Erro ao obter detalhes do exame:", error)
        }
      },
      handleExamDeletedSuccessfully() {
        this.fetchTableData()
      },
    },
    mounted() {
      this.fetchTableData()

      this.$nextTick(() => {
        if (this.$refs.dataTable) {
          this.$refs.dataTable.$on(
            "exam-deleted-successfully",
            this.handleExamDeletedSuccessfully
          )
        }
      })
    },
    beforeDestroy() {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.$off(
          "exam-deleted-successfully",
          this.handleExamDeletedSuccessfully
        )
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";

  body {
    background: #f3f4f6;
  }

  .v-card {
    background: #ffffff;
    border: none;
  }
</style>
