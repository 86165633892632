!
<template>
  <v-row no-gutters>
    <v-col cols="4" md="5"><MachironContainer /></v-col>
    <v-col cols="8" md="7"><LoginForm /></v-col>
  </v-row>
</template>

<script>
  import LoginForm from "@/components/loginForm.vue"
  import MachironContainer from "@/components/machironContainer.vue"
  export default {
    name: "LoginPage",
    components: { MachironContainer, LoginForm },
  }
</script>

<style lang="scss" scoped></style>
